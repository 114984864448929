import React, { useState } from "react";
import contactus from "../../../img/contactus.png";
import {
  Button,
  Card,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useFormik } from "formik";
import useAxios from "../../../api/useAxios";
import { webdetilas } from "../../../utils/Constant";
import CircularProgress from "@mui/material/CircularProgress";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ROUTINGDATA } from "../../ROUTINGDATA";

const validationSchema = Yup.object({
  firstname: Yup.string().required("First Name is required"),
  lastname: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must be valid"
    )
    .required(" Email is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone No. must be exactly 10 digits")
    .required("Phone No. is required"),
  message: Yup.string().required("Message is required"),
});

const ContactPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [popup, setpopup] = useState(false); // state for thank you popup
  const [isLoading, setisLoading] = useState(false); // state for loading indicator
  const axiosData = useAxios();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setisLoading(true);
      const emailContent = `<p><strong>Dear Admin</strong></p>,
      <p>A New Contact Request has Submitted with the following Information</p>
      <p>Contact from URL:www.usglobalsolutions.com </p>
      <p>From</p>
      <p><strong>Name</strong>: ${values.firstname} ${values.lastname} </p>
      <p><strong>Email</strong>: ${values.email} </p>
      <p><strong>Phone Number</strong>: ${values.phone} </p>
      <p><strong>Message</strong>: ${values.message} </p>
      <p><strong>www.usglobalsolutions.com Team. </p>`;
      const data = {
        message: "www.usglobalsolutions.com - client",
        to: webdetilas.toemail,
        content: { message: emailContent },
      };
      const response = await axiosData.post(
        "resend",
        {
          to: data.to,
          subject: "Contact enquiry",
          html: data.content.message,
        },
        {
          headers: {
            Authorization: webdetilas.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        formik.resetForm();
        setisLoading(false);
        setpopup(true);
      }
    },
  });

  return (
    <>
      <br />
      <br />
      <div>
        <img src={contactus} alt="contactus" width="100%" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div
          style={{
            paddingLeft: isMobile ? "0px" : "70px", // Adjust padding for mobile view
            paddingTop: isMobile ? "20px" : "30px",
          }}
        >
          <Card
            sx={{
              boxShadow: "0px 0px 20px 0px #0000001A",
              width: isMobile ? "90%" : "500px",
              gap: "10px",
              borderRadius: "10px",
              padding: isMobile ? "20px" : "10px",
              paddingRight: isMobile ? "10px" : "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                font: "Inter",
                fontSize: isMobile ? "30px" : "40.6px",
                fontWeight: "700",
                lineHeight: isMobile ? "40px" : "59.55px",
                color: "#011334",
                paddingTop: "30px",
                textAlign: isMobile ? "center" : "center", // Center text for mobile view
              }}
            >
              Talk with us
            </Typography>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                font: "Inter",
                fontSize: isMobile ? "16px" : "20.71px",
                fontWeight: "400",
                lineHeight: isMobile ? "20px" : "33.66px",
                textAlign: isMobile ? "center" : "center", // Center text for mobile view
              }}
            >
              Questions, comments, or suggestions? Simply
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  font: "Inter",
                  fontSize: isMobile ? "16px" : "20.71px",
                  fontWeight: "400",
                  lineHeight: isMobile ? "20px" : "23.66px",
                }}
              >
                fill in the form and we’ll be in touch shortly.
              </Typography>
            </Typography>
            <br />
            <Stack
              direction="row"
              spacing={3.5}
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              {" "}
              {/* Center stack for mobile view */}
              <LocationOnOutlinedIcon
                sx={{ color: "blue", fontSize: "35px" }}
              />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18.94px",
                  fontWeight: "700",
                  lineHeight: "28.41px",
                  letterSpacing: "0.06em",
                  font: "Inter",
                }}
              >
                13/33, Kaliamman Koil Street, Chinmaya
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18.94px",
                    fontWeight: "700",
                    lineHeight: "28.41px",
                    letterSpacing: "0.06em",
                    font: "Inter",
                  }}
                >
                  Nagar, Virugambakkam, SBI/Canara Bank
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18.94px",
                    fontWeight: "700",
                    lineHeight: "28.41px",
                    letterSpacing: "0.06em",
                    font: "Inter",
                  }}
                >
                  Building, Chennai - 600092
                </Typography>
              </Typography>
            </Stack>
            <br />
            <Stack
              direction="row"
              spacing={3.5}
              justifyContent={isMobile ? "flex-start" : "flex-start"}
            >
              <CallOutlinedIcon sx={{ color: "blue", fontSize: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18.94px",
                  fontWeight: "700",
                  lineHeight: "28.41px",
                  letterSpacing: "0.06em",
                  font: "Inter",
                }}
              >
                +91 74019 74012
              </Typography>
            </Stack>
            <br />
            <Stack
              direction="row"
              spacing={3.5}
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <EmailOutlinedIcon sx={{ color: "blue", fontSize: "35px" }} />
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18.94px",
                  fontWeight: "700",
                  lineHeight: "28.41px",
                  letterSpacing: "0.06em",
                  font: "Inter",
                }}
              >
                ceo@usglobalsolutions.com
              </Typography>
            </Stack>
          </Card>
        </div>
        <div
          style={{
            paddingLeft: isMobile ? "0px" : "200px",
            paddingTop: "25px",
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-start",

            textAlign: "center",
          }}
        >
          <Card
            sx={{
              boxShadow: "0px 0px 20px 0px #0000001A",

              width: isMobile ? "90%" : "500px",
              gap: "10px",
              borderRadius: "10px",
              padding: isMobile ? "20px" : "10px",
            }}
          >
            <form className="form" onSubmit={formik.handleSubmit}>
              <Stack direction={isMobile ? "column" : "row"} spacing={3}>
                <TextField
                  size="small"
                  placeholder="First Name*"
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={(e) => {
                    if (e) {
                      const name = e.target.value.replace(/[^a-z'. ]/gi, "");
                      formik.setFieldValue("firstname", name);
                    }
                  }}
                  InputProps={{
                    style: {
                      fontWeight: "bold",
                      color: "black",
                    },
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstname && Boolean(formik.errors.firstname)
                  }
                  helperText={
                    formik.touched.firstname && formik.errors.firstname
                  }
                  fullWidth
                ></TextField>
                <TextField
                  size="small"
                  placeholder="Last Name*"
                  name="lastname"
                  value={formik.values.lastname}
                  InputProps={{
                    style: {
                      fontWeight: "bold",
                      color: "black",
                    },
                  }}
                  onChange={(e) => {
                    if (e) {
                      const name = e.target.value.replace(/[^a-z'. ]/gi, "");
                      formik.setFieldValue("lastname", name);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                  helperText={formik.touched.lastname && formik.errors.lastname}
                  fullWidth
                ></TextField>
              </Stack>
              <br />
              <TextField
                size="small"
                placeholder="Email*"
                name="email"
                fullWidth
                value={formik.values.email}
                InputProps={{
                  style: {
                    fontWeight: "bold",
                    color: "black",
                  },
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              ></TextField>
              <br />
              <br />
              <TextField
                size="small"
                placeholder="Phone No*"
                name="phone"
                fullWidth
                value={formik.values.phone}
                InputProps={{
                  style: {
                    fontWeight: "bold",
                    color: "black",
                  },
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.length <= 10) {
                    formik.handleChange(e);
                  }
                }}
                onKeyPress={(e) => {
                  const allowedKeys = /[0-9\b]/;
                  if (!allowedKeys.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              ></TextField>
              <br />
              <br />
              <TextField
                size="small"
                placeholder="Your Message...."
                fullWidth
                name="message"
                InputProps={{
                  style: {
                    fontWeight: "bold",
                    color: "black",
                  },
                }}
                multiline
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              ></TextField>
              <br />
              <br />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <CircularProgress color="primary" className="loader" />
                ) : (
                  "Send Message"
                )}
              </Button>
            </form>
          </Card>
        </div>
        {popup && (
          <div
            className="modal-overlay"
            onClick={() => {
              setpopup(false);
            }}
          >
            <div className="modal-container">
              <DialogTitle
                sx={{
                  color: "#0E2F71",
                  textAlign: "center",
                  font: "Inter",
                  fontWeight: "700",
                }}
              >
                <DoneOutlineRoundedIcon
                  sx={{ fontSize: "70px", color: "#4CAF50" }}
                />
              </DialogTitle>
              <DialogContent>
                <Typography
                  sx={{
                    color: "#717171",
                    textAlign: "center",
                    fontWeight: "500PX",
                    font: "Inter",
                    fontSize: "18px",
                    lineHeight: "19.36px",
                  }}
                >
                  Your quick mail has been sent and your schedule time has been
                  set quickly. Thank you!
                </Typography>
              </DialogContent>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    navigate(ROUTINGDATA.LAND);
                  }}
                >
                  Home
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactPage;
