import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TableName } from "../../../utils/Constant";
import useSupabase from "../../../utils/useSupabase";
import SachinSir from "../../../img/SachinSir.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";

function TeamsPage() {
  const [data, setdata] = useState([]);

  const supabase = useSupabase();
  const fetchData = async () => {
    const { data } = await supabase
      .from(TableName.teams)
      .select()
      .eq("isActive", true)
      .order("id", { ascending: true });
    setdata(data);
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = useMediaQuery("(max-width:600px)");

  const goToLinkedin = () => {
    window.open(
      "https://www.linkedin.com/in/sachin25071974/",
      "_blank"
    );
  };

  return (
    <div style={{ margin: "6rem 0" }}>
      <h1 style={{ textAlign: "center" }}>Meet Our Team</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "1rem",
        }}
      >
        <Avatar
          sx={{
            height: isMobile ? "165px" : "215px",
            width: isMobile ? "165px" : "215px",
          }}
          src={SachinSir}
          alt="ceo"
        />
        <p
          style={{
            fontSize: isMobile ? "16px" : "20px",
            fontWeight: "bold",
          }}
        >
          Sachithanantham Santhosam
          <IconButton
          onClick={goToLinkedin}
          sx={{ color: "#0b66c2" }}
         >
          <LinkedInIcon />
        </IconButton> 
        </p>
        <p
          style={{
            color: "#444",
            fontWeight: 500,
            marginTop: "-1rem",
            fontSize: isMobile ? "14px" : "16px",
          }}
        >
          Founder & CEO of US Global Solutions
        </p>
      </div>
      <br />
      <Grid container spacing={isMobile ? 2 : 4}>
        {data &&
          data.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                marginBottom: isMobile ? "1rem" : "0",
              }}
            >
              <div>
                <Avatar
                  alt={item.name}
                  src={item.imageUrl}
                  sx={{
                    height: isMobile ? "150px" : "200px",
                    width: isMobile ? "150px" : "200px",
                  }}
                />
                <p
                  style={{
                    fontSize: isMobile ? "16px" : "20px",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </p>
                <p
                  style={{
                    color: "blue",
                    fontWeight: 500,
                    marginTop: "-1rem",
                    fontSize: isMobile ? "14px" : "16px",
                  }}
                >
                  {item.role}
                </p>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default TeamsPage;
