import { Container } from '@mui/material'
import {React,useEffect,useState} from 'react'
import m_1 from "../../img/aboutus-img/icon-2.png";
import m_2 from "../../img/aboutus-img/icon-1.png";
export default function MissonAndvission({aboutUs}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div>
        <div id="aboutus-img-content" className="aboutus-img-content">
        <Container>
          <div className="splited-aboutus-content">
            <div className="content-splited">
              <div className="mission">
                <div className="splited-img-icon">
                  <div className="img-icon-mission">
                    <img src={m_1} alt="" />
                  </div>
                  <text>Mission</text>
                </div>
                <p>{aboutUs && aboutUs.infoObject.mission}</p>
              </div>
              {isMobile && (
              <div className="rightsideimg-aboutus">
                <img src={aboutUs ? aboutUs.infoImage : ""} alt="About Us" />
              </div>
              )}
              <div className="Vision">
                <div className="splited-img-icon">
                  <div className="img-icon-mission">
                    <img src={m_2} alt="" />
                  </div>
                  <text>Vision</text>
                </div>
                <p>{aboutUs && aboutUs.infoObject.vision}</p>
              </div>
            </div>
            {!isMobile && (
              <div className="rightsideimg-aboutus">
                <img src={aboutUs ? aboutUs.infoImage : ""} alt="About Us" />
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}
