import React from "react";
import Marquee from "react-fast-marquee";
export default function Ourpartners({ partners }) {
  return (
    <div id="Valuable-Partners" className="Valuable_Partners">
      <div className="titile-div">
        <h3>Our Valuable Partners</h3>
        <p>
          These esteemed partners enrich our offerings, ensuring unparalleled
          quality and expertise in every aspect of our services.
        </p>
      </div>

      <Marquee pauseOnHover gradient className="marqueeborder">
        {partners.map((elements) => {
          return (
            <div className="maequeepartnerimg" style={{ width: "200px" }}>
              <img src={elements.imageUrl} alt="" />
            </div>
          );
        })}
      </Marquee>
    </div>
  );
}
