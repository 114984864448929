import logo from "./logo.svg";
import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Landingpage from "./components/mainpage/Landingpage";
import { ROUTINGDATA } from "./components/ROUTINGDATA";
import Landingpagecontent from "./components/mainpage/Landingpagecontent";
import Productspage from "./components/mainpage/Pages/Productspage";
import TeamsPage from "./components/mainpage/Pages/TeamsPage";
import ContactPage from "./components/mainpage/Pages/ContactPage";
import Popup from "./utils/components/Popup";
import React, { useEffect, useRef, useState } from "react";
import { TableName } from "./utils/Constant";
import useSupabase from "./utils/useSupabase";
import Servicespage from "./components/mainpage/Pages/Servicespage";
import Services from "./components/widget/Services";
import Blog from "./components/mainpage/Pages/Blog";
import BlogDetails from "./components/mainpage/Pages/BlogDetails";

function App() {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([]);
  const featuresRoute = useRef(null);
  const aboutusRoute = useRef(null);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const supabase = useSupabase();
  const [op, setop] = useState();
  const [show, setshow] = useState(true);
  const [Categories, setCategories] = useState([]);

  async function fetchservicesData(newPage, searchKey) {
    try {
      let query = supabase
        .from(TableName.services)
        .select("*", { count: "exact" });
      if (searchKey.trim() !== "") {
        query = query.ilike("servicesName", `%${searchKey}%`);
      }
      query = query.order("servicesName");
      if (filter.length > 0) {
        query = query.in(
          "isActive",
          filter.map((item) => item.code === "yes")
        );
      }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);

      setCategories(data || []);
      setTotalItems(count || 0);

      if (error) {
        console.error("Error fetching categories:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }
  useEffect(
    () => {
      fetchservicesData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  const handleLinkClick = (ref) => {
    const elementPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;
    const offset = 50; // Adjust this value to your desired offset
    window.scrollTo({
      top: elementPosition - offset,
      behavior: "smooth",
    });
    // ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <HashRouter>
      <Routes>
        <Route
          path={ROUTINGDATA.LAND}
          element={
            <Landingpage
              open={open}
              setOpen={setOpen}
              featuresRoute={featuresRoute}
              aboutusRoute={aboutusRoute}
              handleLinkClick={handleLinkClick}
              Categories={Categories}
            />
          }
        >
          <Route
            path={ROUTINGDATA.LANDPAGECONTENT}
            element={
              <Landingpagecontent
                featuresRoute={featuresRoute}
                aboutusRoute={aboutusRoute}
                handleLinkClick={handleLinkClick}
              />
            }
          />
          <Route
            path={ROUTINGDATA.LANDPRODUCTSPAGE}
            element={<Productspage setOpen={setOpen} />}
          />
          <Route path={ROUTINGDATA.TEAMSPAGE} element={<TeamsPage />} />
          <Route
            path={ROUTINGDATA.LANDSERVICES}
            element={<Services categories={Categories} />}
          />
          <Route
            path={ROUTINGDATA.CONTACTUS}
            element={
              <ContactPage
                featuresRoute={featuresRoute}
                aboutusRoute={aboutusRoute}
                handleLinkClick={handleLinkClick}
                Categories={Categories}
              />
            }
          />
          <Route
            path={ROUTINGDATA.LANDSERVICESPAGE}
            element={<Servicespage services={Categories} setOpen={setOpen} />}
          />

          <Route path={ROUTINGDATA.BLOG} element={<Blog />} />
          <Route
            path={`${ROUTINGDATA.BLOGDETAILS}/:id`}
            element={<BlogDetails />}
          />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
