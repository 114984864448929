import React, { useState, useEffect } from "react";
import banner from "../../../img/productspage/Bannerbackground-img.png";
import { Button, Container } from "@mui/material";
import sideimgtop from "../../../img/productspage/top-side-img.png";
import leftsideimg from "../../../img/productspage/top-left-side-img.png";
import img_1 from "../../../img/productspage/image 23.png";
import img_2 from "../../../img/productspage/image 22.png";
import img_3 from "../../../img/productspage/image 24.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSupabase from "../../../utils/useSupabase";
import { TableName } from "../../../utils/Constant";
const Productspage = ({ setOpen }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const supabase = useSupabase();
  const [open, setopen] = useState();
  const [Categories, setCategories] = useState([]);
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });

  const handleClick = (newState) => () => {
    setopen(true);
    setState({ ...newState });
  };
  async function fetchservicesData(newPage, searchKey) {
    try {
      let query = supabase
        .from(TableName.product)
        .select("*", { count: "exact" });
      if (searchKey.trim() !== "") {
        query = query.ilike("productName", `%${searchKey}%`);
      }
      query = query.order("productName");
      if (filter.length > 0) {
        query = query.in(
          "isActive",
          filter.map((item) => item.code === "yes")
        );
      }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);

      setCategories(data || []);
      setTotalItems(count || 0);

      if (error) {
        console.error("Error fetching categories:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }

  useEffect(
    () => {
      fetchservicesData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="banner-img">
        <img src={banner} alt="" />
        <div className="contentbox-banner">
          <div className="connet-box-banner">
            <h1>Elevate Your Journey</h1>
            <p>
              Explore endless possibilities with  velaivendum.com, navigate your
              career path through  career365.in, and nurture your child's
              development with ChildBazaar.com - empowering lives, one platform
              at a time.
            </p>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              variant="contained"
            >
              Request Quote
            </Button>
            <div className="sideimg-top">
              <img src={sideimgtop} alt="" />
            </div>
            <div className="sideimg-top-2">
              <img src={leftsideimg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="productusdetails" className="productusdetails">
        <Container>
          <div className="spliteded-products">
            {Categories &&
              Categories.map((element, index) => {
                if (isMobile) {
                  return (
                    <div className="products-mobileview">
                      <h2 style={{ textAlign: "center" }}>
                        {element.productName}
                      </h2>
                      <img src={element.imageUrl} alt="" />
                      <p>{element.productDesc}</p>
                      <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}} >
                        <Button href={element.productLink} variant="contained">
                          Visit Website
                        </Button>
                      </div>
                    </div>
                  );
                } else if (index % 2 === 0) {
                  return (
                    <>
                      <div>
                        <h2>{element.productName}</h2>
                        <p>{element.productDesc}</p>
                        <div style={{ marginTop: "10px" }}>
                          <Button href={element.productLink} variant="contained">
                            Visit Website
                          </Button>
                        </div>
                      </div>
                      <div className="center-the-img-for-products">
                        <div className="sideimg-productus-details">
                          <img src={element.imageUrl} alt="" />
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="center-the-img-for-products">
                        <div className="sideimg-productus-details">
                          <img
                            style={{ width: "442px", height: "254px" }}
                            src={element.imageUrl}
                            alt=""
                          />
                        </div>
                      </div>
                      <div>
                        {" "}
                        <h2>{element.productName}</h2>
                        <p>{element.productDesc}</p>
                        <div style={{ marginTop: "10px" }}>
                          <Button href={element.productLink} variant="contained">
                            Visit Website
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Productspage;
