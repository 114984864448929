import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  InputLabel,
  Typography,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { webdetilas } from "../Constant";
import CircularProgress from "@mui/material/CircularProgress";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "minimum 3 letters")
    .max(50, "maximum 50 letters")
    .required("Name is required"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d{10}$/, "Mobile number is not valid"),
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must be valid"
    )
    .required("Email is required"),
  services: Yup.object().nullable().required("Service is required"),
});

const Popup = ({ open, setOpen, axiosData, Categories }) => {
  const [isLoading, setisLoading] = useState(false);
  const [thankYouOpen, setThankYouOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      services: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      setisLoading(true);
      const emailContent = `
      <h3>Contact Us Form Submission</h3>
      <p><strong>Name:</strong> ${values.name}</p>
      <p><strong>services:</strong> ${values.services.servicesName}</p>
      <p><strong>Email:</strong> ${values.email}</p>
      <p><strong>Phone No.:</strong> ${values.mobile}</p>
    `;
      const data = {
        message: "usglobalsolutions.com - client",
        to: webdetilas.toemail,
        content: { message: emailContent },
      };
      const response = await axiosData.post(
        "resend",
        {
          to: data.to,
          subject: values.services.servicesName + " enquiry",
          html: data.content.message,
        },
        {
          headers: {
            Authorization: webdetilas.token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        handleClose();
        setisLoading(false);
        formik.resetForm();
        setThankYouOpen(true); // Open thank you pop-up
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
  };

  const handleThankYouClose = () => {
    setThankYouOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#0E2F71",
            textAlign: "center",
            font: "Inter",
            fontWeight: "700",
          }}
        >
          Request Quote
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <InputLabel sx={{ paddingTop: "5px" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500PX",
                  font: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Full Name
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <TextField
              size="small"
              margin="dense"
              name="name"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.name}
              onChange={(e) => {
                if (e) {
                  const name = e.target.value.replace(/[^a-z'. ]/gi, "");
                  formik.setFieldValue("name", name);
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <InputLabel sx={{ paddingTop: "5px" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500PX",
                  font: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Mobile Number
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <TextField
              margin="dense"
              name="mobile"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              value={formik.values.mobile}
              onChange={(e) => {
                if (e) {
                  const mobile = e.target.value.replace(/[^0-9'. ]/gi, "");
                  formik.setFieldValue("mobile", mobile);
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            <InputLabel sx={{ paddingTop: "5px" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500PX",
                  font: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Email
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <TextField
              margin="dense"
              size="small"
              name="email"
              type="email"
              fullWidth
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <InputLabel sx={{ paddingTop: "5px" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "500PX",
                  font: "Inter",
                  fontSize: "16px",
                  lineHeight: "19.36px",
                }}
              >
                Choose Service
                <Typography sx={{ color: "red" }} component="span">
                  *
                </Typography>
              </Typography>
            </InputLabel>
            <Autocomplete
              fullWidth
              value={formik.values.services}
              name="services"
              onChange={(event, value) => {
                console.log(value);
                formik.setFieldValue("services", value);
              }}
              onBlur={() => formik.setFieldTouched("services", true)}
              options={Categories}
              getOptionLabel={(option) => option.servicesName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  margin="dense"
                  name="services"
                  variant="outlined"
                  error={
                    formik.touched.services && Boolean(formik.errors.services)
                  }
                  helperText={formik.touched.services && formik.errors.services}
                />
              )}
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress color="primary" className="loader" />
                ) : (
                  "Submit"
                )}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={thankYouOpen}
        onClose={handleThankYouClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "400px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "#0E2F71",
            textAlign: "center",
            font: "Inter",
            fontWeight: "700",
          }}
        >
          <DoneOutlineRoundedIcon sx={{ fontSize: "70px", color: "#4CAF50" }} />
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: "#717171",
              textAlign: "center",
              fontWeight: "500PX",
              font: "Inter",
              fontSize: "18px",
              lineHeight: "19.36px",
            }}
          >
            Your request for a quote has been successfully submitted. Our team
            will review your request and get back to you shortly.
          </Typography>
        </DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          paddingBottom="10px"
        >
          <Button
            onClick={handleThankYouClose}
            color="primary"
            variant="contained"
          >
            Done
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default Popup;
