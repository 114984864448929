export const AppRouts = {
  SIDEBAR: "/",
  ADDservices_WITHOUT_ID: "/addservices",
  Technology: "/technology",
  services: "services",
  ADDtechnology_WITHOUT_ID: "/addtechnology",
  ADDtechnology: "/technology/:id",
  ADDServices: "services/:id",
  Blog: "/Blog",
  BLOGDETAILS: "/BlogDetails",
};
export const TableName = {
  technology: "technology",
  services: "services",
  info: "info",
  product: "product",
  teams: "team",
  partners: "partners",
  banner: "banner",
  aboutus: "aboutus",
};
export const webdetilas = {
  toemail: "ceo@usglobalsolutions.com",
  token:"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJlam91ZGp3dHF6YnZsaGl0dHB0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA3NzA5MjIsImV4cCI6MjAzNjM0NjkyMn0.hNyIWQFc33LoVmG4XI4ya0NfMMyQv9Qn8GewEy8drCQ"
};
export const StorageBucket = {
  images: "images",
};
