import React, { useState } from "react";
import Landingpageheader from "./Landingpageheader";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../mainpage/Landingpage.css";
import {
  Autocomplete,
  Button,
  Container,
  IconButton,
  TextField,
} from "@mui/material";
import logo from "../../img/logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import { useFormik } from "formik";
import { useEffect } from "react";
import { webdetilas } from "../../utils/Constant";
import useAxios from "../../api/useAxios";
import Popup from "../../utils/components/Popup";
import * as Yup from "yup";
import { ROUTINGDATA } from "../ROUTINGDATA";
import CircularProgress from "@mui/material/CircularProgress";
const validationSchema = Yup.object({
  full_name: Yup.string().required("Full Name is required"),
  company_organization: Yup.string().required(
    "Company/Organization is required"
  ),
  email_id: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must be valid"
    )
    .required("Company Mail is required"),
  services: Yup.string().required("Services is required"),
  message: Yup.string().required("Message is required"),
});
const Landingpage = ({
  open,
  setOpen,
  featuresRoute,
  aboutusRoute,
  handleLinkClick,
  Categories,
}) => {
  const axiosData = useAxios();
  const [show, setshow] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [routername, setroutername] = useState("");
  const formik = useFormik({
    initialValues: {
      full_name: "",
      company_organization: "",
      email_id: "",
      services: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("eee");
      setisLoading(true);
      const emailContent = `<p><strong>Dear Admin</strong></p>,
      <p>A New Contact Request has Submitted with the following Information</p>
      <p>Contact from URL:www.usglobalsolutions.com </p>
      <p>From</p>
      <p><strong>Name</strong>: ${values.full_name} </p>
      <p><strong>Email</strong>: ${values.email} </p>
      <p><strong>Message</strong>: ${values.message} </p>
      <p><strong>www.usglobalsolutions.com Team. </p>`;
      const data = {
        message: "usglobalsolutions.com - client",
        to: webdetilas.toemail,
        content: { message: emailContent },
      };
      const response = await axiosData.post("resend", {
        to: data.to,
        subject: values.services + " enqenquiry",
        html: data.content.message,
      }, {
        headers: {
          'Authorization': webdetilas.token,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
      }

      formik.resetForm();
      setisLoading(false);
    },
  });
 
  useEffect(
    () => {
      window.scrollTo(0, 0);
      const currentUrl = location.pathname;

      const urlArray = currentUrl.split("/");
      if (urlArray[1] === "Contacts") {
        setshow(false);
      } else {
        if (routername === urlArray[1]) {
          setshow(true)
        } else {
          formik.resetForm();
          formik.setFieldValue("services", ''); 
          setshow(false)
          setTimeout(() => {
            setshow(true)   
          }, 1000);       
          setroutername(urlArray[1])
        }
        ;
      }
    },
    [location]
  );
  
  const goToInstagram = () => {
    window.open("https://www.instagram.com/usglobalsolutions/", "_blank");
  };
  const goToFacebook = () => {
    window.open("https://www.facebook.com/usglobalsolution/", "_blank");
  };
  const goToLinkedin = () => {
    window.open(
      "https://www.linkedin.com/company/us-global-solutions/mycompany/",
      "_blank"
    );
  };
  const goToXapp = () => {
    window.open("https://x.com/usglobal2006", "_blank");
  };
  return (
    <div>
      <div>
        <Landingpageheader
          open={open}
          setOpen={setOpen}
          featuresRoute={featuresRoute}
          aboutusRoute={aboutusRoute}
          handleLinkClick={handleLinkClick}
        />
      </div>
      <div>
        <Outlet />
      </div>
      <br />
      <div>
        <div id="footer" className="footer">
          <Container>
            <div className="splited_footer">
              <div className="box-1-splited">
                <div>
                  <div className="footer-logo">
                    <img src={logo} alt="" />
                  </div>
                  <p>
                    US Global Solutions Staffing has been coupling top talent
                    with the best companies in the industry Since 2005. We are
                    an elite professional recruiting firm specializing in
                    Progress 4GL and opEdge technologies.
                  </p>
                  <div className="contantdetails">
                    <div className="splited-contantdetails">
                      <div>
                        <MailOutlineIcon />
                      </div>
                      <div>
                        <text>ceo@usglobalsolutions.com</text>
                      </div>
                    </div>
                    <div className="splited-contantdetails">
                      <div>
                        <CallIcon />
                      </div>
                      <div>
                        <text> +91 9940688891</text>
                      </div>
                    </div>
                    <div className="splited-contantdetails">
                      <div>
                        <LocationOnIcon />
                      </div>
                      <div>
                        <text style={{padding:"5px"}}>
                          13/33 Kaliamman Koil Street Chinmaya Nagar,
                          Virugambakkam SBI/Canara Bank Building Chennai -
                          600092
                        </text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box_2_splited">
                  <div>
                    <h2>Quick Links</h2>
                    <ul className="footerUlList">
                      <li  onClick={() => {
                          navigate("/");
                        }}>Home</li>
                      <li
                        onClick={() => {
                          navigate("/");
                          setTimeout(() => {
                            handleLinkClick(aboutusRoute);
                          }, 500);
                        }}
                      >
                        <text> About Us</text>
                      </li>
                      <li
                        onClick={() => {
                          navigate("/");
                          setTimeout(() => {
                            handleLinkClick(featuresRoute);
                          }, 500);
                        }}
                      >
                        Services
                      </li>
                      <li
                        onClick={() => {
                          navigate(ROUTINGDATA.LAND + ROUTINGDATA.CONTACTUS);
                        }}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  <div style={{marginTop: "15px"}}>
                    <h2>Services</h2>
                    <ul className="footerUlList">
                      {Categories &&
                        Categories.map((element) => {
                          return (
                            <li
                              onClick={() => {
                               

                                navigate(
                                  ROUTINGDATA.LAND +
                                    ROUTINGDATA.LANDSERVICES +
                                    element.servicesCode
                                );
                                // window.location.reload();
                              }}
                            >
                              {element.servicesName}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="footer-icons" style={{marginTop: "15px"}}>
                    <div>
                      <IconButton
                        onClick={goToFacebook}
                        sx={{ color: "white" }}
                      >
                        <FacebookIcon />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        onClick={goToInstagram}
                        sx={{ color: "white" }}
                      >
                        <InstagramIcon />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton sx={{ color: "white" }}>
                        <YouTubeIcon />
                      </IconButton>
                    </div>
                    <div className="footer-icon-img">
                      <IconButton onClick={goToXapp} sx={{ color: "white" }}>
                        <XIcon />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        onClick={goToLinkedin}
                        sx={{ color: "white" }}
                      >
                        <LinkedInIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {show &&  (
                  <div className="form">
                    <form onSubmit={formik.handleSubmit}>
                      <h3>Quick Mail</h3>
                      <div className="jobseeker-box-inputs">
                        <div className="fotter-textfield">
                          <label>Full Name</label>
                          <TextField
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            id="full_name"
                            required
                            variant="outlined"
                            size="small"
                            name="full_name"
                            placeholder="Enter Your Full Name"
                            error={
                              formik.touched.full_name &&
                              formik.errors.full_name
                            }
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e) {
                                const name = e.target.value.replace(
                                  /[^a-z'. ]/gi,
                                  ""
                                );
                                formik.setFieldValue("full_name", name);
                              }
                            }}
                            value={formik.values.full_name}
                            helperText={
                              formik.touched.full_name &&
                              formik.errors.full_name
                                ? formik.errors.full_name
                                : null
                            }
                            style={{
                              color:
                                formik.touched.full_name &&
                                formik.errors.full_name
                                  ? "red"
                                  : "inherit",
                            }}
                            fullWidth
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                          />
                        </div>
                        <div className="fotter-textfield">
                          <label>Company/Organization</label>
                          <TextField
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            id="company_organization"
                            required
                            variant="outlined"
                            size="small"
                            name="company_organization"
                            placeholder=" Enter Company/Organization"
                            error={
                              formik.touched.company_organization &&
                              formik.errors.company_organization
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.company_organization}
                            helperText={
                              formik.touched.company_organization &&
                              formik.errors.company_organization
                                ? formik.errors.company_organization
                                : null
                            }
                            style={{
                              color:
                                formik.touched.company_organization &&
                                formik.errors.company_organization
                                  ? "red"
                                  : "inherit",
                            }}
                            fullWidth
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                          />
                        </div>
                        <div className="fotter-textfield">
                          <label>Company Mail</label>
                          <TextField
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            id="email_id"
                            required
                            variant="outlined"
                            size="small"
                            name="email_id"
                            placeholder="Enter company mail"
                            error={
                              formik.touched.email_id && formik.errors.email_id
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email_id}
                            helperText={
                              formik.touched.email_id && formik.errors.email_id
                                ? formik.errors.email_id
                                : null
                            }
                            style={{
                              color:
                                formik.touched.full_name &&
                                formik.errors.full_name
                                  ? "red"
                                  : "inherit",
                            }}
                            fullWidth
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                          />
                        </div>
                        <div className="fotter-textfield">
                          <label>Services</label>
                          <Autocomplete
                            size="small"
                            required
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            options={
                              Categories &&
                              Categories.map((element) => element.servicesName)
                            }
                            onChange={(event, value) => {
                              formik.setFieldValue("services", value);
                            }}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                              {...params}
                                required
                                name="services"
                                value={formik.values.services}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.services &&
                                  Boolean(formik.errors.services)
                                }
                                helperText={
                                  formik.touched.services &&
                                  formik.errors.services
                                    
                                }
                                style={{
                                  color:
                                    formik.touched.services &&
                                    formik.errors.services
                                      ? "red"
                                      : "inherit",
                                }}
                                placeholder="Please Select"
      
                              />
                            )}
                            
                            ListboxProps={{
                              style: { maxHeight: "180px", overflowY: "auto" },
                            }}
                          />
                        </div>
                        <div className="fotter-textfield">
                          <label>Your message</label>
                          <TextField
                            multiline
                            rows={3}
                            sx={{ marginTop: "8px", backgroundColor: "white" }}
                            id="comments"
                            required
                            variant="outlined"
                            size="small"
                            name="message"
                            error={
                              formik.touched.message && formik.errors.message
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            helperText={
                              formik.touched.message && formik.errors.message
                                ? formik.errors.message
                                : null
                            }
                            style={{
                              color:
                                formik.touched.message && formik.errors.message
                                  ? "red"
                                  : "inherit",
                            }}
                            fullWidth
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "30px", marginBottom: "5px" }}>
                          <Button
                            type="submit"
                            component="label"
                            variant="contained"
                            onClick={() => {
                              console.log("ddd", formik.dirty, formik.isValid);

                              formik.handleSubmit();
                            }}
                            fullWidth
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <CircularProgress
                                color="primary"
                                className="loader"
                              />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
            <div className="copyrights-part">
              Copyright © 2024 US Global Solutions. All rights reserved
            </div>
          </Container>
        </div>
      </div>
      <Popup
        open={open}
        setOpen={setOpen}
        axiosData={axiosData}
        Categories={Categories}
      ></Popup>
    </div>
  );
};

export default Landingpage;
