import React from "react";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
export default function Services({ categories }) {
    const navigate = useNavigate();

  return (
    <div id="services" className="services">
      <div className="titile-div">
        <h3>Services</h3>
        <p>
          Enhancing your website with a suite of dynamic services crafted to
          elevate user interaction and stimulate lasting connections.
        </p>
      </div>
      <Container>
              <div className="services-boxs-splited">
          {categories &&
            categories.map((element) => {
                console.log(categories, "categories")

              return (
                <div
                  className="box-services-card"
                  onClick={() => {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.LANDSERVICES +
                        element.servicesCode
                    );
                  }}
                >
                  <div className="box-services-img">
                    <img src={element.imageUrl} alt="" />
                  </div>
                  <h3>{element.servicesName} </h3>
                      <p>{element.servicesDesc}</p>
                </div>
              );
            })}
        </div>
        {/* <div className="services-boxs-splited">
            <div className="box-services-card">
              <div className="box-services-img">
                <img src={services_1} alt="" />
              </div>
              <h3>IT product development service </h3>
              <p>
                IT product development services, offering expertise in crafting
                intuitive designs, proficiency in the latest tech stacks, and
                effective project management to bring your ideas to life
                effortlessly.
              </p>
            </div>
            <div className="box-services-card">
              <div className="box-services-img">
                <img src={services_2} alt="" />
              </div>
              <h3>Web Development Services </h3>
              <p>
                Transform your online presence with our expert web development
                services. We create sleek, responsive websites tailored to your
                needs, ensuring a seamless user experience. Elevate your brand
                and engage your audience with our professional solutions.
              </p>
            </div>
            <div className="box-services-card">
              <div className="box-services-img">
                <img src={services_3} alt="" />
              </div>
              <h3>Web Development Services </h3>
              <p>
                Elevate your business with our top-notch mobile app development
                service. We specialize in crafting dynamic and user-friendly
                applications for both iOS and Android platforms. Transform your
                ideas into reality and stay ahead in the mobile landscape with
                our expertise.
              </p>
            </div>
            <div className="box-services-card">
              <div className="box-services-img">
                <img src={services_4} alt="" />
              </div>
              <h3>End-to-End Digital Marketing </h3>
              <p>
                Power up your brand online with our full-suite digital
                marketing. From strategy to execution, we've got you covered
                across social media, SEO, content, and email. Elevate
                engagement, boost visibility, and maximize ROI with us leading
                the way in the digital realm.
              </p>
            </div>
            <div className="box-services-card">
              <div className="box-services-img">
                <img src={services_5} alt="" />
              </div>
              <h3>Staffing Solution </h3>
              <p>
                Transform your workforce with our staffing solutions, offering
                tailored talent for every business requirement. Our streamlined
                hiring process ensures efficiency, saving you valuable time and
                resources. Elevate your team's potential and success with our
                expert staffing services.
              </p>
            </div>
            <div className="box-services-card">
              <div className="box-services-img">
                <img src={services_6} alt="" />
              </div>
              <h3>Case Studies / Portfolio Services </h3>
              <p>
                Discover success in action through our concise case studies and
                portfolio service. Uncover real-world examples of our impactful
                solutions, demonstrating our expertise. Elevate your confidence
                in choosing us as your strategic partner for tangible business
                success
              </p>
            </div>
          </div> */}
      </Container>
    </div>
  );
}
