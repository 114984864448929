import { Container } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import useSupabase from "../../utils/useSupabase";
import { TableName } from "../../utils/Constant";
import AboutUs from "../widget/AboutUs";
import MissonAndvission from "../widget/MissonAndvission";
import Services from "../widget/Services";
import Ourproduct from "../widget/Ourproduct";
import Ourpartners from "../widget/Ourpartners";
import Popup from "../../utils/components/Popup";
import useAxios from "../../api/useAxios";
const Landingpagecontent = ({ featuresRoute, aboutusRoute }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const axiosData = useAxios();
  const [filter, setFilter] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);
  const [aboutUs, setaboutUs] = useState();
  const [partners, setpartners] = useState([]);
  const [ourProducts, setourProducts] = useState([]);
  const [open, setopen] = useState(false);
  const supabase = useSupabase();

  const fetchAboutUs = async () => {
    try {
      const data = await supabase
        .from(TableName.info)
        .select("*")
        .eq("screenName", "AboutUs")
        .single();
      setaboutUs(data.data);
    } catch {}
  };

  const fetchPartners = async () => {
    try {
      const data = await supabase.from(TableName.partners).select("*");
      setpartners(data.data);
    } catch {}
  };

    const fetchBanner = async () => {
        try {
            const data = await supabase.from(TableName.banner).select("*").eq("isActive",true);
            setourProducts(data.data)
        } catch { }
    };
    async function fetchservicesData(newPage, searchKey) {
        try {
            let query = supabase
                .from(TableName.services)
                .select("*", { count: "exact" });
            if (searchKey.trim() !== "") {
                query = query.ilike("servicesName", `% ${ searchKey } %`);
            }
            query = query.order("servicesName");
            if (filter.length > 0) {
                query = query.in(
                    "isActive",
                    filter.map((item) => item.code === "yes")
                );
            }

      const { data, count, error } = await query
        .range(newPage * rowsPerPage, (newPage + 1) * rowsPerPage - 1)
        .limit(rowsPerPage);

      console.log("Total Items:", count);

      setCategories(data || []);
      setTotalItems(count || 0);

      if (error) {
        console.error("Error fetching categories:", error);
      }
    } catch (error) {
      console.error("Error performing search:", error);
    }
  }
  useEffect(
    () => {
      fetchAboutUs();
      fetchPartners();
      fetchBanner();
      fetchservicesData(0, searchTerm);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  return (
    <div>
      <Ourproduct open={open} setopen={setopen} ourProducts={ourProducts}></Ourproduct>
      <br></br>
      <br />
      <Container>
        <div ref={aboutusRoute}>
          <AboutUs aboutUs={aboutUs}></AboutUs>
        </div>
      </Container>
      <MissonAndvission aboutUs={aboutUs}></MissonAndvission>
      <div ref={featuresRoute}>
        <Services categories={categories}></Services>
      </div>
      <Ourpartners partners={partners}></Ourpartners>
      <Popup
        open={open}
        setOpen={setopen}
        axiosData={axiosData}
        Categories={categories}
      ></Popup>
    </div>
  );
};

export default Landingpagecontent;
