export const ROUTINGDATA = {
  LAND: "/",
  LANDPAGECONTENT: "",
  LANDPRODUCTSPAGE: "products",
  LANDSERVICESPAGE: "service/:code",
  LANDSERVICES: "service/",
  TEAMSPAGE: "TeamsPage",
  CONTACTUS: "Contacts",
  BLOG: "Blog",
  BLOGDETAILS: "/BlogDetails",
};
