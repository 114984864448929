import React, { useState } from "react";
import banner from "../../../img/servicepage-img/web-devel-important 1.png";
import { Button, Container } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useSupabase from "../../../utils/useSupabase";
import { TableName } from "../../../utils/Constant";
import Ourproduct from "../../widget/Ourproduct";
import Ourpartners from "../../widget/Ourpartners";

const Servicespage = ({ services, setOpen }) => {
  const location = useLocation();
  const { code } = useParams();
  const supabase = useSupabase();
  const [loadind, setloading] = useState(false);
  const [service, setservices] = useState({});
  const [technology, settechnology] = useState();
  const [partners, setpartners] = useState([]);
  const [ourProducts, setourProducts] = useState([]);
  const fetchPartners = async () => {
    try {
      const data = await supabase.from(TableName.partners).select("*");
      setpartners(data.data);
    } catch {}
  };
  const fetchBanner = async () => {
    try {
      const data = await supabase.from(TableName.banner).select("*");
      setourProducts(data.data);
    } catch {}
  };
  // Using useSearchParams to get the search params
  useEffect(() => {
    // Get individual search params

    // const code = searchParams.get("code");
    const fetchdata = async () => {
      setloading(false);
      const { data, error } = await supabase
        .from(TableName.services)
        .select()
        .eq("servicesCode", code)
        .single();
      setservices({ ...data });
      const technologydata = await supabase
        .from(TableName.technology)
        .select("*")
        .in("technologyName", [
          "React",
          "MongoDB",
          "Node js",
          "NEXT",
          "React Native",
        ])
        .limit(5);
      console.log(technologydata);
      settechnology([...technologydata.data]);
      setloading(true);
    };
    fetchdata();
    fetchBanner();
    fetchPartners();
  }, [code]);
  return (
    <>
      {loadind ? (
        <div>
          <div className="bannerconnet-services" style={{ marginTop: "30px" }}>
            <div className="servicescenter-conent">
              <h1>{service.servicesName}</h1>
              <p>{service.servicesDesc}</p>
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                variant="contained"
              >
                Request Quote
              </Button>
            </div>
          </div>
          <div id="Our-Offered-Services" className="Our-Offered-Services">
            <div className="titile-div">
              <h3>Our Offered Services</h3>
              <p>
                At our company, we excel in delivering a variety of innovative
                web development solutions meticulously crafted to suit your
                unique requirements
              </p>
            </div>
            <Container>
              <div className="splited-the-services-area">
                <div>
                  <LanguageIcon className="icon-splited" />
                  <h3>Websites</h3>
                  <p>
                    {" "}
                    Elevate your online presence with our expertly crafted
                    websites, designed to captivate your audience and showcase
                    your brand's unique identity.
                  </p>
                </div>
                <div>
                  <ShoppingCartIcon className="icon-splited" />
                  <h3>E-commerce</h3>
                  <p>
                    Launch your online shop confidently with our e-commerce
                    development services, boasting intuitive interfaces, secure
                    payments, and seamless shopping experiences.
                  </p>
                </div>
                <div>
                  <AppShortcutIcon className="icon-splited" />
                  <h3>Web Application</h3>
                  <p>
                    From dynamic web portals to interactive e-learning
                    platforms, our team is equipped to develop custom web
                    applications that streamline your business processes and
                    drive growth.
                  </p>
                </div>
                <div>
                  <SettingsSystemDaydreamIcon className="icon-splited" />
                  <h3>Content Management System</h3>
                  <p>
                    Take control of your digital content with our customized CMS
                    solutions, empowering you to manage and update your website
                    effortlessly.
                  </p>
                </div>
              </div>
            </Container>
          </div>

          {/* Our Agile Approach */}

          <div id="Technologies" className="Technologies">
            <Container>
              <p>
                Technologies that we majorly use for <text>Website</text> &{" "}
                <text>App Development</text>
              </p>
              <div className="technologies-img">
                {technology &&
                  technology.map((element) => {
                    return (
                      <div>
                        <img src={element.imageUrl} alt="" />
                      </div>
                    );
                  })}
              </div>
            </Container>
          </div>

          <Ourproduct ourProducts={ourProducts}></Ourproduct>
          <Ourpartners partners={partners}></Ourpartners>
        </div>
      ) : (
        <div
          style={{
            height: "70vh",
            display: "grid",
            placeItems: "center",
            // transform: "translate(0,50vh)",
          }}
        >
          <div class="dots"></div>
        </div>
      )}
    </>
  );
};

export default Servicespage;
