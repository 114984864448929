import { useMediaQuery, Button, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../../img/banner/Desktop - 53.png";
import image2 from "../../img/banner/Desktop - 54.png";
import image3 from "../../img/banner/Desktop - 55.png";
import image4 from "../../img/banner/Desktop - 56.png";
import image5 from "../../img/banner/Desktop - 57.png";
import image6 from "../../img/banner/Desktop - 58.png";
import image7 from "../../img/banner/Desktop - 59.png";

import { TableName } from "../../utils/Constant";
import useSupabase from "../../utils/useSupabase";
export default function OurProduct({ open, setopen }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const supabase = useSupabase();
  // const data = [
  //   {
  //     header: "WEB DEVELOPMENT SERVICES",
  //     body: "Elevate your online brand with our expertly crafted web development services, designed to engage your audience and drive meaningful results.",
  //     image: image4,
  //   },
  //   {
  //     header: "DIGITAL MARKETING SERVICES",
  //     body: "where digital success is our specialty. We offer expert digital marketing services tailored to elevate your brand's online presence. From SEO and social media management to content creation, we're here to help your business thrive in the digital realm.",
  //     image: image5,
  //   },
  //   {
  //     header: "PRODUCT DEVELOPMENT",
  //     body: "where digital success is our specialty. We offer expert digital marketing services tailored to elevate your brand's online presence. From SEO and social media management to content creation, we're here to help your business thrive in the digital realm.",
  //     image: image6,
  //   },
  //   {
  //     header: "MOBILE APP DEVELOPMENT",
  //     body: "where digital success is our specialty. We offer expert digital marketing services tailored to elevate your brand's online presence. From SEO and social media management to content creation, we're here to help your business thrive in the digital realm.",
  //     image: image7,
  //   },
  //   {
  //     header: "Comprehensive E-Learning Solutions",
  //     body: "We are developing Educampus, an e-learning platform powered by React.js, to enhance knowledge acquisition and serve as one of our flagship products.",
  //     image: image1,
  //   },
  //   {
  //     header: "Job Seekers Platform",
  //     body: "Career365 is a cutting-edge job-seeking mobile app, seamlessly built with React Native and MongoDB to deliver a fast and intuitive experience. Discover your next career opportunity with our powerful, user-friendly platform designed to simplify your job search.",
  //     image: image2,
  //   },
  //   {
  //     header: "Velai Vendum.com",
  //     body: "Velai Vendum is a career portal website designed to assist users from both rural and urban areas. It caters to students, graduates, working professionals, and housewives, offering various career opportunities and resources.",
  //     image: image3,
  //   },
  // ];
  const [data, setdata] = useState();
  const getbanner = async () => {
    const { data: bannerData, error: bannerError } = await supabase
      .from(TableName.banner)
      .select()
      .eq("isActive", true);
    if (bannerError) {
      console.error("Error fetching categories:", bannerError);
      return;
    }

    setdata(bannerData);
  };
  useEffect(() => {
    getbanner();
  }, []);

  return (
    <div style={{ height: isMobile ? "100%" : "70vh", paddingTop: "65px" }}>
      <Carousel
        showArrows={true}
        autoPlay={true}
        interval={2000}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
      >
        {data &&
          data.map((item, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                style={{
                  height: isMobile ? "100%" : "75vh",
                  objectFit: "cover",
                }}
                src={item.imageUrl}
                alt="productImage"
              />
             
                <Box
                  sx={{
                    position: "absolute",
                    top: "60%",
                    left: "5%",
                    transform: "translateY(-50%)",
                    padding: "20px",
                    color: item.bodyColor,
                    maxWidth: isMobile ? "100%" : "42%",
                    textAlign: "left",
                  }}
                > {item.header && 
                  <Typography
                    gutterBottom
                    style={{
                      fontSize: isMobile ? "16px" : "30px",
                      fontWeight: "bold",
                      color: item.headeColor,
                    }}
                  >
                    {item.header}
                  </Typography>}
               {item.body &&<Typography
                  variant="body1"
                  gutterBottom
                  style={{
                    display: isMobile ? "none" : "block",
                    color: item.bodyColor,
                  }}
                >
                  {item.body}
                </Typography>}
                 { item.link &&<Box mt={2}>
                    <Button
                      sx={{
                        mr: 2,
                        fontSize: isMobile ? "10px" : "13px",
                        textTransform: "lowercase",
                        color: "#303030",
                        backgroundColor: "white",
                        borderColor: "#4C86F9",

                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "#303030",
                          borderColor: "#4C86F9",
                          backgroundColor: "white",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      variant="outlined"
                      href={item.link}
                    >
                      Explore More
                    </Button>
                    <Button
                      sx={{
                        fontSize: isMobile ? "10px" : "13px",
                        textTransform: "lowercase",
                        color: "white",
                        backgroundColor: "#4C86F9",
                        borderColor: "#FFFFFF",

                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "white",
                          borderColor: "#FFFFFF",
                          backgroundColor: "#4C86F9",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      variant="outlined"
                      onClick={() => {
                        setopen(!open);
                      }}
                    >
                      Request Quote
                    </Button>
                  </Box>}
                </Box>
              
            </div>
          ))}
      </Carousel>
    </div>
  );
}
