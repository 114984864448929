import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../img/logo.png";
import { Button } from "@mui/material";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useLocation, useNavigate } from "react-router-dom";

const Landingpageheader = ({ setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const getActiveStyle = (path) => {
    return location.pathname === path
      ? { borderBottom: "2px solid #4c86f9" }
      : {};
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className="header-design">
        <Toolbar className="splited-header">
          <div className="logo-header">
            <img
              src={logo}
              alt=""
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTENT);
              }}
            />
          </div>

          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className="nav-btns"
          >
            <text
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTENT);
              }}
              style={getActiveStyle(
                ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTENT
              )}
            >
              Home
            </text>
            {/* <text>About</text> */}

            <text
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPRODUCTSPAGE);
              }}
              style={getActiveStyle(
                ROUTINGDATA.LAND + ROUTINGDATA.LANDPRODUCTSPAGE
              )}
            >
              Products
            </text>
            <text
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDSERVICES);
              }}
              style={getActiveStyle(
                ROUTINGDATA.LAND + ROUTINGDATA.LANDSERVICES
              )}
            >
              Services
            </text>
            {/* <text>Partners</text> */}
            <text
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.TEAMSPAGE);
              }}
              style={getActiveStyle(ROUTINGDATA.LAND + ROUTINGDATA.TEAMSPAGE)}
            >
              Our Team
            </text>
            <text
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.BLOG);
              }}
              style={getActiveStyle(ROUTINGDATA.LAND + ROUTINGDATA.BLOG)}
            >
              Blog
            </text>
            <text
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.CONTACTUS);
              }}
              style={getActiveStyle(ROUTINGDATA.LAND + ROUTINGDATA.CONTACTUS)}
            >
              Contact Us
            </text>
          </Box>

          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            Request Quote
          </Button>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        sx={{ right: 0 }}
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <List>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTENT);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{ fontWeight: "bold", color: "#4c86f9", textAlign: "center" }}
              primary="Home"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPRODUCTSPAGE);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{ fontWeight: "bold", color: "#4c86f9", textAlign: "center" }}
              primary="Products"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDSERVICES);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{ fontWeight: "bold", color: "#4c86f9", textAlign: "center" }}
              primary="Services"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LAND + ROUTINGDATA.TEAMSPAGE);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{ fontWeight: "bold", color: "#4c86f9", textAlign: "center" }}
              primary="Our Team"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LAND + ROUTINGDATA.BLOG);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{ fontWeight: "bold", color: "#4c86f9", textAlign: "center" }}
              primary="Blog"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              navigate(ROUTINGDATA.LAND + ROUTINGDATA.CONTACTUS);
              setDrawerOpen(false);
            }}
          >
            <ListItemText
              sx={{ fontWeight: "bold", color: "#4c86f9", textAlign: "center" }}
              primary="Contact Us"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setOpen(true);
              setDrawerOpen(false);
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Button variant="contained">Request Quote</Button>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Landingpageheader;
