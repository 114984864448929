import React from "react";
import about_1 from "../../img/landingpage-about-us-img/ri_service-line.png";
import about_2 from "../../img/landingpage-about-us-img/basil_file-outline.png";
import about_3 from "../../img/landingpage-about-us-img/streamline_smiley-happy.png";
import star from "../../img/landingpage-about-us-img/starts.png";
export default function AboutUs({ aboutUs }) {
  return (
    <div>
      {" "}
      <div className="aboutus-box">
        <h2>About us</h2>
        <p>{aboutUs && aboutUs.infoObject.title}</p>
        <div className="splited_3_aboutus">
          <div className="content_Points-aboutus">
            <div className="abouts-img">
              <img src={about_1} alt="" />
            </div>
            <div className="aboutus-points-box">
              <h3>20+ years</h3>
              <text>Experience</text>
            </div>
          </div>
          <div className="content_Points-aboutus">
            <div className="abouts-img">
              <img src={about_3} alt="" />
            </div>
            <div className="aboutus-points-box">
              <h3>10k+</h3>
              <text>Happy customers</text>
            </div>
          </div>
          <div className="content_Points-aboutus">
            <div className="abouts-img">
              <img src={about_2} alt="" />
            </div>
            <div className="aboutus-points-box">
              <h3>30+</h3>
              <text>Projects completed</text>
            </div>
          </div>
        </div>
        <div className="star-img">
          <img src={star} alt="" />
        </div>
      </div>
    </div>
  );
}
